#faq-section {
  max-width: unset;
  margin: 256px auto;
}

#faq-section h1 {
  color: #469f38;
  background: linear-gradient(90deg, #1e201980 0%, #1e2019 50%, #1e201980 100%);
  margin: 0 auto 128px;
  padding: 16px 32px;
}

#faq-section h1 span {
  color: #e2efb3;
}

#faq-blocks-cont {
  max-width: 2200px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

#faq-blocks-cont .faq-block {
  cursor: url("cursor-pointer.2fbfb758.png"), auto;
  perspective: 1000px;
  width: 480px;
  height: 480px;
  position: relative;
}

#faq-blocks-cont .faq-block.flip-me > div.question-side {
  transform: rotateY(180deg);
}

#faq-blocks-cont .faq-block.flip-me > div.answer-side {
  transform: rotateY(360deg);
}

#faq-blocks-cont .faq-block.is-animating {
  z-index: 3;
}

#faq-blocks-cont .faq-block > div {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 4%;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  transition: transform .5s, box-shadow .5s;
  display: flex;
  position: absolute;
}

#faq-blocks-cont .faq-block > div.question-side {
  z-index: 1;
}

#faq-blocks-cont .faq-block > div.question-side p {
  text-align: center;
  font-family: Permanent Marker, cursive;
  font-size: 24px;
}

#faq-blocks-cont .faq-block > div.answer-side {
  z-index: 0;
  background-color: #1e2019;
  transform: rotateY(180deg);
}

#faq-blocks-cont .faq-block > div.answer-side p {
  color: #e2efb3;
}

#faq-blocks-cont .faq-block p {
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
}

#faq-blocks-cont .faq-block p:nth-of-type(2) {
  margin-top: 16px;
}

#faq-blocks-cont .faq-block p a {
  color: #35a4cd !important;
}

#faq-blocks-cont .faq-block:nth-child(1) > .question-side p, #faq-blocks-cont .faq-block:nth-child(3) > .question-side p, #faq-blocks-cont .faq-block:nth-child(4) > .question-side p, #faq-blocks-cont .faq-block:nth-child(6) > .question-side p, #faq-blocks-cont .faq-block:nth-child(7) > .question-side p {
  color: #fff;
}

#faq-blocks-cont .faq-block:nth-child(1) > .question-side {
  background-color: #469f38;
}

#faq-blocks-cont .faq-block:nth-child(1) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #3c8730, inset -20px -20px 60px #51b740;
}

#faq-blocks-cont .faq-block:nth-child(2) > .question-side {
  background-color: gold;
}

#faq-blocks-cont .faq-block:nth-child(2) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #cbbe14, inset -20px -20px 60px #ffff1c;
}

#faq-blocks-cont .faq-block:nth-child(3) > .question-side {
  background-color: #35a4cd;
}

#faq-blocks-cont .faq-block:nth-child(3) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #2d8bae, inset -20px -20px 60px #3dbdec;
}

#faq-blocks-cont .faq-block:nth-child(4) > .question-side {
  background-color: #1a4f20;
}

#faq-blocks-cont .faq-block:nth-child(4) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #16431b, inset -20px -20px 60px #1e5b25;
}

#faq-blocks-cont .faq-block:nth-child(5) > .question-side {
  background-color: #e2efb3;
}

#faq-blocks-cont .faq-block:nth-child(5) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #c0cb98, inset -20px -20px 60px #ffffce;
}

#faq-blocks-cont .faq-block:nth-child(6) > .question-side {
  background-color: #ff4500;
}

#faq-blocks-cont .faq-block:nth-child(6) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #d93b00, inset -20px -20px 60px #ff4f00;
}

#faq-blocks-cont .faq-block:nth-child(7) > .question-side {
  background-color: #8b4513;
}

#faq-blocks-cont .faq-block:nth-child(7) > .question-side:hover {
  box-shadow: inset 20px 20px 60px #763b10, inset -20px -20px 60px #a04f16;
}

@media (min-width: 1101px) {
  #faq-blocks-cont {
    gap: 72px;
  }
}

@media (max-width: 1100px) {
  #faq-blocks-cont {
    display: block;
  }

  #faq-blocks-cont .faq-block {
    width: 100%;
    height: 260px;
  }

  #faq-blocks-cont .faq-block > div {
    border-radius: unset;
  }
}

@media (max-width: 870px) {
  #faq-section {
    margin: 128px auto;
    padding: 0;
  }

  #faq-section h1 {
    margin-bottom: 64px;
    padding: 16px 32px;
  }

  #faq-blocks-cont .faq-block {
    height: 120px;
    transition: height .5s;
  }

  #faq-blocks-cont .faq-block.flip-me {
    height: 180px;
  }

  #faq-blocks-cont .faq-block:nth-child(1) > .question-side:after, #faq-blocks-cont .faq-block:nth-child(3) > .question-side:after, #faq-blocks-cont .faq-block:nth-child(4) > .question-side:after, #faq-blocks-cont .faq-block:nth-child(6) > .question-side:after, #faq-blocks-cont .faq-block:nth-child(7) > .question-side:after {
    background-image: url("tap-light.9845d881.svg");
  }

  #faq-blocks-cont .faq-block > div.question-side:after {
    content: " ";
    width: 24px;
    height: 24px;
    z-index: 3;
    background-image: url("tap-dark.34211846.svg");
    background-size: cover;
    margin-top: -12px;
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
  }

  #faq-blocks-cont .faq-block > div.question-side p {
    text-align: left;
    padding-right: 16px;
    font-size: 20px;
  }

  #faq-blocks-cont .faq-block p {
    font-size: 18px;
  }
}

/*# sourceMappingURL=index.4c11b3ed.css.map */
