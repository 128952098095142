@import "/src/constants";

#faq-section {
  max-width: unset;
  margin: 256px auto;

  h1 {
    margin: 0 auto 128px;
    padding: 16px 32px;
    color: $green;
    background: titleBg($dark-chocolate);

    span {
      color: $beige;
    }
  }
}

#faq-blocks-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 2200px;
  margin: 0 auto;

  .faq-block {
    position: relative;
    cursor: url("/src/assets/images/cursor-pointer.png"), auto;
    perspective: 1000px;
    width: 480px;
    height: 480px;

    &.flip-me > div {
      &.question-side {
        transform: rotateY(180deg);
      }

      &.answer-side {
        transform: rotateY(360deg);
      }
    }

    &.is-animating {
      z-index: 3;
    }

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      transition: 0.5s transform, 0.5s box-shadow;
      backface-visibility: hidden;
      border-radius: $standard-border-radius;

      &.question-side {
        z-index: 1;

        p {
          font-size: 24px;
          text-align: center;
          font-family: "Permanent Marker", cursive;
        }
      }

      &.answer-side {
        z-index: 0;
        transform: rotateY(180deg);
        background-color: $dark-chocolate;

        p {
          color: $beige;
        }
      }
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      margin: 0;

      &:nth-of-type(2) {
        margin-top: 16px;
      }

      a {
        color: $blue !important;
      }
    }

    &:nth-child(1) > .question-side p,
    &:nth-child(3) > .question-side p,
    &:nth-child(4) > .question-side p,
    &:nth-child(6) > .question-side p,
    &:nth-child(7) > .question-side p {
      color: white;
    }

    &:nth-child(1) > .question-side {
      background-color: $green;

      &:hover {
        box-shadow: inset 20px 20px 60px #3c8730, inset -20px -20px 60px #51b740;
      }
    }

    &:nth-child(2) > .question-side {
      background-color: $yellow;

      &:hover {
        box-shadow: inset 20px 20px 60px #cbbe14, inset -20px -20px 60px #ffff1c;
      }
    }

    &:nth-child(3) > .question-side {
      background-color: $blue;

      &:hover {
        box-shadow: inset 20px 20px 60px #2d8bae, inset -20px -20px 60px #3dbdec;
      }
    }

    &:nth-child(4) > .question-side {
      background-color: $dark-green;

      &:hover {
        box-shadow: inset 20px 20px 60px #16431b, inset -20px -20px 60px #1e5b25;
      }
    }

    &:nth-child(5) > .question-side {
      background-color: $beige;

      &:hover {
        box-shadow: inset 20px 20px 60px #c0cb98, inset -20px -20px 60px #ffffce;
      }
    }

    &:nth-child(6) > .question-side {
      background-color: orangered;

      &:hover {
        box-shadow: inset 20px 20px 60px #d93b00, inset -20px -20px 60px #ff4f00;
      }
    }

    &:nth-child(7) > .question-side {
      background-color: saddlebrown;

      &:hover {
        box-shadow: inset 20px 20px 60px #763b10, inset -20px -20px 60px #a04f16;
      }
    }
  }
}

@media (min-width: 1101px) {
  #faq-blocks-cont {
    gap: 72px;
  }
}

@media (max-width: 1100px) {
  #faq-blocks-cont {
    display: block;

    .faq-block {
      width: 100%;
      height: 260px;

      > div {
        border-radius: unset;
      }
    }
  }
}

@media (max-width: 870px) {
  #faq-section {
    padding: 0;
    margin: 128px auto;

    h1 {
      margin-bottom: 64px;
      padding: 16px 32px;
    }
  }

  #faq-blocks-cont {
    .faq-block {
      height: 120px;
      transition: 0.5s height;

      &.flip-me {
        height: 180px;
      }

      &:nth-child(1) > .question-side:after,
      &:nth-child(3) > .question-side:after,
      &:nth-child(4) > .question-side:after,
      &:nth-child(6) > .question-side:after,
      &:nth-child(7) > .question-side:after {
        background-image: url("/src/assets/images/icons/tap-light.svg");
      }

      > div.question-side {
        &:after {
          position: absolute;
          display: block;
          content: " ";
          background-image: url("/src/assets/images/icons/tap-dark.svg");
          background-size: cover;
          width: 24px;
          height: 24px;
          z-index: 3;
          top: 50%;
          margin-top: -12px;
          right: 16px;
        }

        p {
          font-size: 20px;
          text-align: left;
          padding-right: 16px;
        }
      }

      p {
        font-size: 18px;
      }
    }
  }
}
